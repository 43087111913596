<template>
  <div>   
    <CRow>
      <CCol sm="6" lg="12" style="text-align:center; margin-bottom:20px;">
         <h2 v-if="!isMobile()">¡Bienvenid@ {{ user.name }}!</h2>
         <h2 v-if="isMobile()">¡Bienvenid@!</h2>
         <CRow style="margin-top: 10px; margin-bottom: 10px; float: right; padding-right: 15px;">
          <CButton color="info" style="min-width: 130px;" size="sm">{{period_name}}</CButton>
          <CDropdown color="info" togglerText="" size="sm" style="margin-left: 5px;">
            <CDropdownItem v-for="(range, i) in periods" :key="'range'+i"  @click="changePeriod(range)">{{range.name}}</CDropdownItem>
          </CDropdown>          
        </CRow>
      </CCol>
    </CRow>
    
    <CRow>
      <CCol :md="2" ></CCol>
      <CCol :md="4" >
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CIcon name="cil-grid"/> Visitas Realizadas (Por los promotores)
            </slot>
          </CCardHeader>
          <CCardBody>
            <CRow v-if="metrics_1.items.length > 0">
              <CCol :md="12" >
                <CDataTable
                  :hover="hover"
                  :striped="striped"
                  :border="border"
                  :small="small"
                  :fixed="fixed"
                  :items="metrics_1.items"
                  :fields="fieldsMetric"
                  :items-per-page="2"                  
                  class="table-metrics"                  
                >
                  <template #periodo="{item}"><td class="text-center">{{ item.period }}</td></template>
                  <template #universo="{item}"><td class="w100 text-center">{{ item.total }}</td></template>
                  <template #realizado="{item}"><td class="w100 text-center">{{ item.done }}</td></template>      
                  <template #porcentaje="{item}"><td class="w100 text-center">{{ Math.round(item.rate) }} %</td></template>
                </CDataTable>
              </CCol>             
            </CRow>
            <span v-else>NO SE HAN DEFINIDO VISITAS</span>
          </CCardBody>
        </CCard>
      </CCol>  
      <CCol :md="4" >
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CIcon name="cil-grid"/> Visitas Revisadas (Por el KAM)
            </slot>
          </CCardHeader>
          <CCardBody>
            <CRow v-if="metrics_1.items.length > 0">
              <CCol :md="12" >
                <CDataTable
                  :hover="hover"
                  :striped="striped"
                  :border="border"
                  :small="small"
                  :fixed="fixed"
                  :items="metrics_1.items"
                  :fields="fieldsMetric2"
                  :items-per-page="2"                  
                  class="table-metrics"                  
                >
                  <template #periodo="{item}"><td class="text-center">{{ item.period }}</td></template>
                  <template #universo="{item}"><td class="w100 text-center">{{ item.total }}</td></template>
                  <template #realizado="{item}"><td class="w100 text-center">{{ item.done }}</td></template>      
                  <template #validado="{item}"><td class="w100 text-center">{{ item.validated }}<b style="font-size: 14px;"> + </b><span style="color: red">{{ item.rejected }}</span></td></template>
                  <template #porcentaje="{item}"><td class="w100 text-center">{{ Math.round(item.rate_validated) }} %</td></template>
                </CDataTable>
              </CCol>             
            </CRow>
            <span v-else>NO SE HAN DEFINIDO VISITAS</span>
          </CCardBody>
        </CCard>
      </CCol>  
    </CRow>
   
    <CRow>
      <CCol lg="3">
        <CCard border-color="warning" style="min-height: 600px; max-height: 600px;">
          <CCardHeader style="background-color: #da5d2d !important; text-align:center;" text-color="white" text-align="center">
            CLIENTES
          </CCardHeader>
          <CCardBody style="min-height: 485px; max-height: 585px; overflow:auto;">
            <CCardText >
              <CRow >
                <CCol >                  
                  <CListGroup flush v-if="customers.length > 0">
                    <CListGroupItem v-for="customer in customers" :key="'customer_'+customer.id">
                      <span>{{customer.name}}</span>
                      <CButton color="danger" size="sm" style="float: right; height: 30px;" @click="link('customer',customer)">Ver</CButton>
                    </CListGroupItem>
                  </CListGroup>   
                  <div v-else style="text-align: center; width: 100%;">NO SE ENCONTRARON CLIENTES PARA EL PERIODO SELECCIONADO</div>                            
                </CCol>
              </CRow>
            </CCardText>
          </CCardBody>          
        </CCard>
      </CCol>
      <CCol lg="3">
        <CCard border-color="warning" style="min-height: 600px; max-height: 600px;">
          <CCardHeader color="success" style="text-align:center;" text-color="white" text-align="center">
            RUTAS NACIONALES
          </CCardHeader>
          <CCardBody style="min-height: 485px; max-height: 585px; overflow:auto;">
            <CCardText>
              <CRow style="padding: 0 20px; text-align: center;" v-if="user && user.role == 'kam' && user.supports.length > 0">
                <div style="width: 100%">
                  <table style="width: 100%">
                    <tr>
                      <td><CButton color="success" style="width: 330px;" size="sm">{{kam.name}}</CButton></td>
                      <td style="text-align: right;">
                        <CDropdown color="success" togglerText="" size="sm" style="margin-left: 5px; width: 30px">
                          <CDropdownItem v-for="(support, i) in user.supports" :key="'range'+i"  @click="changeSupport(support)">{{support.name}}</CDropdownItem>
                        </CDropdown>
                      </td>
                    </tr>
                  </table>
                  <br />
                </div>         
              </CRow>
              <CRow>
                <CCol>                  
                  <CListGroup flush v-if="rutas.length > 0">
                    <CListGroupItem v-for="ruta in rutas" :key="'ruta_'+ruta.id">
                      <CRow>
                        <CCol :md="10">
                          <CRow>
                            <CCol :md="12">
                              <span>{{ruta.route_name}}</span>
                            </CCol>                      
                            <CCol :md="12">
                              <span style="font-size: x-small;">{{ruta.name}}</span>    
                              <br v-if="ruta.visits > 0" />
                              <span v-if="ruta.visits > 0" style="color: red; font-size: 10px;"><b>{{ruta.visits}}</b> {{ruta.visits > 1 ? 'Visitas' : 'Visita'}} por Validar</span>
                            </CCol>                      
                          </CRow>
                      </CCol>                
                        <CCol :md="2">
                          <CButton color="danger" size="sm" style="float: right; height: 30px;" @click="link('ruta',ruta)">Ver</CButton>
                        </CCol>                      
                      </CRow>
                      <!-- <span>{{ruta.route_name}}</span>
                      <CButton color="danger" size="sm" style="float: right; height: 30px;" @click="link('ruta',ruta)">Ver</CButton> -->
                    </CListGroupItem>
                  </CListGroup> 
                  <div v-else style="text-align: center; width: 100%;">NO SE ENCONTRARON RUTAS PARA EL PERIODO SELECCIONADO</div>               
                </CCol>
              </CRow>
            </CCardText>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol lg="3">
        <CCard border-color="warning" style="min-height: 600px; max-height: 600px;">
          <CCardHeader color="warning" style="text-align:center;" text-color="white" text-align="center">
            UNIVERSOS
          </CCardHeader>
          <CCardBody style="min-height: 485px; max-height: 585px; overflow:auto;">
            <CCardText>
              <CRow>
                <CCol>                  
                  <CListGroup flush v-if="universos.length > 0">
                    <CListGroupItem v-for="(universo, i) in universos" :key="'universo_'+i">
                      <CRow>
                        <CCol :md="10">
                          <CRow>
                            <CCol :md="12">
                              <span>{{universo.name}}</span>
                            </CCol>       
                            <!--               
                            <CCol :md="12">
                              <span style="font-size: x-small;">{{universo.description}}</span>    
                            </CCol>                      
                            -->
                          </CRow>
                      </CCol>                
                        <CCol :md="2">
                          <CButton color="danger" size="sm" style="float: right; height: 30px;" @click="link('universo',universo)">Ver</CButton>
                        </CCol>                      
                      </CRow>
                    </CListGroupItem>
                  </CListGroup>     
                  <div v-else style="text-align: center; width: 100%;">NO SE ENCONTRARON UNIVERSOS PARA EL PERIODO SELECCIONADO</div>                          
                </CCol>
              </CRow>
            </CCardText>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol lg="3">
        <CCard border-color="warning" style="min-height: 600px; max-height: 600px;">
          <CCardHeader color="info" style="text-align:center;" text-color="white" text-align="center">
            RUTAS REZAGADAS
          </CCardHeader>
          <CCardBody style="min-height: 485px; max-height: 585px; overflow:auto;">
            <CCardText>
              <CRow>
                <CCol>                  
                  <CListGroup flush v-if="generics.length > 0">
                    <CListGroupItem v-for="generic in generics" :key="'generico_'+generic.id">
                      <CRow>
                        <CCol :md="10">
                          <CRow>
                            <CCol :md="12">
                              <span>{{generic.route_name}}</span>
                            </CCol>                      
                            <CCol :md="12">
                              <span style="font-size: x-small;">{{generic.name}}</span>    
                            </CCol>                      
                          </CRow>
                        </CCol>                
                        <CCol :md="2">
                          <CButton color="danger" size="sm" style="float: right; height: 30px;" @click="link('generico',generic)">Ver</CButton>
                        </CCol>                      
                      </CRow>
                      <!-- <span>{{generic.route_name}}</span>
                      <CButton color="danger" size="sm" style="float: right; height: 30px;" @click="link('generico',generic)">Ver</CButton> -->
                    </CListGroupItem>
                  </CListGroup>   
                  <div v-else style="text-align: center; width: 100%;">NO SE ENCONTRARON RUTAS REZAGADAS PARA EL PERIODO SELECCIONADO</div>                            
                </CCol>
              </CRow>
            </CCardText>
          </CCardBody>
        </CCard>
      </CCol>
      <!-- <CCol lg="3">
        <CCard border-color="warning" style="min-height: 590px; max-height: 590px;">
          <CCardHeader color="info" style="text-align:center;" text-color="white" text-align="center">
            OTROS
          </CCardHeader>
          <CCardBody style="min-height: 475px; max-height: 575px; overflow:auto;">
            <br>
            <br>
            <CRow>
              <CCol lg="12" style="text-align:center;">
                <h3>Tu Universo Rezagado</h3>                                                 
              </CCol>
              <CCol  lg="12" style="text-align:center;">         
                <CIcon style="width: 100%; height: 100px;" name="cilWarning" />                                                        
              </CCol>
            </CRow>
            <br> <br> <br>
            <CRow>
              <CCol lg="12" style="text-align:center;">
                <h3>Personal Fijo</h3>                                                 
              </CCol>
              <CCol  lg="12" style="text-align:center;">         
                <CIcon style="width: 100%; height: 100px;" name="cilGroup" />                                                        
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol> -->
    </CRow>
  </div>
</template>
<script>

import store from '../../store'
import router from '../../router/index'
import ws_kam from '../../services/kam';
import moment from 'moment';
import moment_timezone from 'moment-timezone';
import { CChartPie } from '@coreui/vue-chartjs'
import 'moment/locale/es';

export default {
  name: 'KamHome',
  components: {
    CChartPie,
  },
  data () {
    return {      
      page: 'kamHome',
      periods: [],
      customers: [],
      rutas: [],
      universos: [],
      generics: [],
      metrics_1 : {
        items : [],
      },
      metrics_2 : {
        items : [],
      },
      kam: false,
      user: {
        name:""
      },
      period: 202310,
      period_date: ["2023-10-01", "2023-10-31"],
      period_name: "",
      fieldsMetric: [        
        {key: 'periodo', label: 'Periodo'},
        {key: 'universo', label: 'Universo'},
        {key: 'realizado', label: 'Realizado'},
        {key: 'porcentaje', label: '%'},
      ],
      fieldsMetric2: [        
        {key: 'periodo', label: 'Periodo'},
        {key: 'universo', label: 'Universo'},
        {key: 'realizado', label: 'Realizado'},
        {key: 'validado', label: 'Validado'},
        {key: 'porcentaje', label: '%'},
      ],
      backgroundColors:[
        '#41B883',
        '#E46651',
        '#00D8FF',
        '#DD1B16',
        '#2F4F4F',
        '#708090',	
        '#696969',
        '#A52A2A',
        '#8B4513',
        '#DAA520',
        '#BC8F8F',
        '#000080',
        '#7B68EE',
        '#ADD8E6',
        '#40E0D0',
        '#9ACD32',
        '#90EE90',
        '#ADFF2F',
        '#800080',
        '#6A5ACD',
        '#DDA0DD',
        '#BDB76B',
        '#FFFF00',
        '#FF69B4',
        '#CD5C5C',
        '#FF5733',
      ],
      hover: true,
      striped: false,
      border: true,
      small: false,
      fixed: false,
      dark: true
    }
  },
  computed: {
    defaultDatasets () {
      return [
        {
          backgroundColor: this.backgroundColors,
          data: [this.metrics_1.total.done,(this.metrics_1.total.total - this.metrics_1.total.done)]
        }
      ]
    },
  },
  mounted: async function() {
    this.loading();

    let is_kam = localStorage.getItem("is_kam");

    if(is_kam == "false"){
      let is_administrator = localStorage.getItem("is_administrator");

      if(is_administrator == "true"){
        window.location.href = "/#/graphs/general"; this.loaded(); return;
      }
      else{
        window.location.href = "/#/home"; this.loaded(); return;
      }
    }

    await this.setPeriods();

    if(localStorage.getItem("period_date") === null || localStorage.getItem("period_date") === undefined){
      let name = moment().startOf('month').format("MMMM Y");
      name = name.charAt(0).toUpperCase() + name.slice(1);

      let date = [
        moment().startOf('month').format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD")
      ];

      let period = moment().startOf('month').format("YYYYMM");

      localStorage.setItem("period_date", JSON.stringify(date));
      localStorage.setItem("period", period);
      localStorage.setItem("period_name", name);
    }

    this.period_date = JSON.parse(localStorage.getItem("period_date"));
    this.period = localStorage.getItem("period");
    this.period_name = localStorage.getItem("period_name");
    this.kam = JSON.parse(localStorage.getItem("kam"));
    this.user = JSON.parse(localStorage.getItem("user"));

    let last_login = localStorage.getItem("last_login");
    let today = moment().format("YYYY-MM-DD");

    if(last_login === null || last_login === undefined || last_login != today){
      last_login = today;
      localStorage.setItem("last_login", last_login);

      await this.setPeriods();

      await this.init(this.kam, this.period_date);
    }
    else{
      await this.reinit();
    }

    this.loaded();
  },
  methods: {
    setMetricHeight () {      
      // let max = Math.max(this.metrics_1.items.length, this.metrics_2.items.length);
      // if(max == 0){
      //   return 75;
      // }else if(max <= 5){
      //   return 500 +(68 * max);
      // }else{
      //   return 720;
      // }
    },
    init: async function(kam, date){
      this.loading();
      
      if(kam){
        this.kam = kam;
        this.role = this.kam.role;

        localStorage.setItem("kam", JSON.stringify(this.kam));
        localStorage.setItem("user", JSON.stringify(this.user));
      }
      else{
        let response_kam_info = await ws_kam.getKamInfo();
        if(response_kam_info.type == "success"){
          this.user = response_kam_info.data;
          this.kam = response_kam_info.data;
          localStorage.setItem("user", JSON.stringify(this.user));
          localStorage.setItem("kam", JSON.stringify(this.kam));

          this.role = this.kam.role;

          if(this.role == "kam" && this.kam.supports.length > 0){
            this.kam = this.kam.supports[0];
          }
        }
      }

      let filters = {"date" : date, "kam_id": this.kam.id};
        
      let response_customers = await ws_kam.getCustomerList(filters);
      if(response_customers.type == "success"){
        this.customers = response_customers.data;
        localStorage.setItem("customers", JSON.stringify(this.customers));
      }

      let response_rutas = await ws_kam.getRutaList(filters);
      if(response_rutas.type == "success"){
        this.rutas = response_rutas.data;
        localStorage.setItem("rutas", JSON.stringify(this.rutas));
      }

      let response_universos = await ws_kam.getUniversoList(filters);
      if(response_universos.type == "success"){
        this.universos = response_universos.data;
        localStorage.setItem("universos", JSON.stringify(this.universos));
      }

      let response_genericos = await ws_kam.getGenericList(filters);
      if(response_genericos.type == "success"){
        this.generics = response_genericos.data;
        localStorage.setItem("generics", JSON.stringify(this.generics));
      }

      let response_metrics = await ws_kam.homeVisitsMetrics(filters);
      if(response_metrics.type == "success"){
        this.metrics_1 = response_metrics.data.metric_1;
        localStorage.setItem("home_metrics_1", JSON.stringify(this.metrics_1));
      }
      this.loaded();
    },
    initSupport: async function(kam, date){
      this.loading();
      
      this.kam = kam;
      this.role = this.kam.role;

      localStorage.setItem("kam", JSON.stringify(this.kam));
      localStorage.setItem("user", JSON.stringify(this.user));

      let filters = {"date" : date, "kam_id": this.kam.id};

      let response_rutas = await ws_kam.getRutaList(filters);
      if(response_rutas.type == "success"){
        this.rutas = response_rutas.data;
        localStorage.setItem("rutas", JSON.stringify(this.rutas));
      }

      this.loaded();
    },
    reinit: async function(){
      this.kam = JSON.parse(localStorage.getItem("kam"));
      this.user = JSON.parse(localStorage.getItem("user"));
      this.role = this.kam.role;
      this.customers = JSON.parse(localStorage.getItem("customers"));
      this.rutas = JSON.parse(localStorage.getItem("rutas"));
      this.universos = JSON.parse(localStorage.getItem("universos"));
      this.generics = JSON.parse(localStorage.getItem("generics"));
      this.metrics_1 = JSON.parse(localStorage.getItem("home_metrics_1"));
    },
    changePeriod: async function(range){
      this.loading();
      this.period_name  = range.name; 

      localStorage.setItem("period_date", JSON.stringify(range.value));
      localStorage.setItem("period", range.value[0].substr(0,4)+""+range.value[0].substr(5,2));
      localStorage.setItem("period_name", range.name);

      let today = moment().format("YYYY-MM-DD");
      localStorage.setItem("last_login", today);

      this.period_date = range.value;

      await this.init(this.kam, this.period_date);
      this.loaded();
    },
    changeSupport: async function(support){
      this.loading();

      this.kam = support;
      localStorage.setItem("kam", this.kam);

      await this.initSupport(this.kam, this.period_date);
      this.loaded();
    },        
    setPeriods: async function (by = "month") {
      this.periods = [];
      if(by == "month"){
        let name = moment().startOf('month').add(1,"month").format("MMMM Y");
        name = name.charAt(0).toUpperCase() + name.slice(1);
        this.periods.push({
          id: -1,
          name: name,
          value: [
            moment().startOf('month').add(1,"month").format("YYYY-MM-DD"),
            moment().startOf('month').add(1,"month").endOf("month").format("YYYY-MM-DD"),
          ],
          is_first: true,
        });          

        for (let index = 0; index < 4; index++) {
          let name = moment().startOf('month').subtract(index,"month").format("MMMM Y");
          name = name.charAt(0).toUpperCase() + name.slice(1);
          this.periods.push({
            id: index,
            name: name,
            value: [
              moment().startOf('month').subtract(index,"month").format("YYYY-MM-DD"),
              (index == 0) ?  moment().endOf("month").format("YYYY-MM-DD") : moment().startOf('month').subtract(index,"month").endOf("month").format("YYYY-MM-DD"),
            ],
            is_first: (index == 0) ? true : false,
          });                  
        }
        name = moment().startOf('month').format("MMMM Y");
        name = name.charAt(0).toUpperCase() + name.slice(1);
        this.period_name  = name;
      }else{
        for (let index = 0; index < 12; index++) {
          this.periods.push({
            id: index,
            name: moment().startOf('month').subtract(index,"month").format("MMM Y") + " a " + moment().startOf('month').subtract(index,"month").add(1,"month").format("MMM Y"),
            value: {
              start: moment().startOf('month').subtract(index,"month"),
              end: moment().startOf('month').subtract(index,"month").add(1,"month"),
            }
          });                  
        }
        this.period_name  = moment().startOf('month').format("MMM Y") + " a " + moment().startOf('month').add(1,"month").format("MMM Y");
      }
    },       
    showToast (type, message){
        store.commit('toast', {color: type, message: message});
    },
    link (type, item){
      localStorage.removeItem("last_login");
      switch (type) {
        case "customer":
          this.$router.push('/customer/'+item.id)
          break;
        case "ruta":
          this.$router.push('/ruta/'+item.id)
          break;
        case "universo":
          this.$router.push('/universo/'+item.id)
          break;
        case "generico":
          this.$router.push('/ruta/generico/'+item.id)
          break;                          
        default:
          break;
      }      
    },
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
<style>
.table-metrics table {
  font-size: 12px !important;
}
.table-metrics table td{
  padding: 5px;
}

</style>
